<template>
  <WidgetFrame @onResize="onResize" @onOrientationChange="onOrientationChange">
    <template v-slot:title>
      Kanban Board
    </template>
    <template v-slot:content>
      <div v-if="!canView('PROJECT', ['TASK'])" class="center-text">
        {{ $t('entity_selector.error.insufficient_permission_to_show_data') }}
      </div>
      <template v-else-if="!loaded">
        <div class='status-message'>Loading...</div>
      </template>
      <template v-else-if="noStages">
        <div class='status-message'>No project stages defined.</div>
      </template>
      <template v-else-if="noTasks">
        <div class='status-message'>No tasks in project.</div>
      </template>
      <template v-else>
        <ag-charts-vue v-if="loaded" :options="options"></ag-charts-vue>    
      </template>
    </template>
  </WidgetFrame>
</template>

<script>
  import {AgChartsVue} from 'ag-charts-vue';
  import WidgetFrame from "@/components/Dashboard/WidgetFrame";
  import settings from "@/_dashboardSettings";
  import { EventBus } from '@/helpers';
  import { stageService } from '@/services';
  import { tooltipRendererGenerator } from '@/helpers/ag-chart-tooltip-renderer';

  export default {
    name: 'ProjectKanbanBoardWidget',
    components: {
      AgChartsVue,
      WidgetFrame
    },
    props: {
      project:    { type: Object, required: true },
      taskCount:  { type: Number, default: 0 }
    },
    watch: {
      tasks: function () {
        this.buildData();
      }
    },
    data() {
      return {
        orientation: null,
        width: 0,
        height: 0,
        loaded: false,
        options: null,
        sourceData: [],
        noStages: false,
        noTasks: false,
      };
    },
    created() {
      EventBus.$on('theme-change', () => {
        if (this.loaded) {
          this.buildChart();
        }
      });

      this.buildData();
    },
    beforeDestroy() {
      EventBus.$off('theme-change');
    },
    methods: {
      async buildData() {
        this.sourceData = [];
        const self = this;
        if (!this.project.stageList) {
          this.noStages = true;
          this.loaded = true;
          return;
        }

        // The kanban board allows tasks of type 'Task' and 'Milestone', so we must
        // only consider those as valid tasks in the calculations
        //var tasks = this.tasks.filter(t => t.type == 'Task' || t.type == 'Milestone');

        // Dict of counters for each stage.
        let occur = {'_backlog_': 0};
        this.project.stageList.forEach(function(stage) {
          occur[stage.uuId] = 0;
        })

        const stageData = await stageService.dashboardListNames(this.project.uuId).then(response => {
          return response.data;
        }).catch(e => {
          //eslint-disable-next-line
          console.error(e);
        });

        let totalStaged = 0;
        for (const s of stageData) {
          totalStaged += s.count;
        }
        occur['_backlog_'] = this.taskCount - totalStaged;
        this.sourceData.push({'stage': 'Backlog', 'count': occur['_backlog_']});
        this.project.stageList.forEach(function(stage) {
          const data = stageData.find(s => s.uuId === stage.uuId);
          
          self.sourceData.push({'stage': stage.name, 'count': data ? data.count : 0});
        });
        this.buildChart();
        this.loaded = true;
      },
      buildChart() {
        var total = this.sourceData.reduce((total, stage) => total + stage.count, 0);
        this.options = settings.getPieChartOptions(this.orientation, this.width, this.height, this.sourceData.length);
        this.options.title.text = total + ' Tasks';
        this.options.series[0].data = this.sourceData;
        this.options.series[0].calloutLabelKey = 'stage';
        this.options.series[0].angleKey = 'count';
        this.options.series[0].tooltip = {
          enabled: true,
          renderer: tooltipRendererGenerator({ title: 'datum["stage"]', staticContentLabel: 'Tasks', contentValue: 'datum.count' })
        }
      },
      onOrientationChange({orientation, width, height}) {
        // console.log(this.$options.name + ": Orientation change");
        this.orientation = orientation;
        this.onResize({width, height});
      },
      onResize({width, height}) {
        // console.log(this.$options.name + ": W: " + width + " H: " + height);
        this.width = width;
        this.height = height;
        if (this.loaded) {
          this.buildChart();
        }
      }
    }
  }
</script>
<style lang="scss" scoped>
  .center-text {
    text-align: center;
    margin: auto;
  }
</style>